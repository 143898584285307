.cs-bg-color {
  background-color: #282c34;
}

.cs-header-margin {
  margin-top: 64px;
}

.cs-f-1 {
  flex: 1;
  flex-grow: 1;
}

.cs-pointer {
  cursor: pointer;
}

.cs-floating {
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1000;
}